<template>
  <div :class="`m-3 wrapper wrapper--border wrapper--${bu}`">
    <Loading v-if="loading" />
    <div v-else>
      <h3 class="text-center">
        Klanten van: {{ name }}, van het jaar: {{ year }}
      </h3>
      <table :class="`table--perweek table__border--${bu}`">
        <thead :class="`table__head--${bu}`">
          <th class="table__head--text">Nummer</th>
          <th class="table__head--text">Naam</th>
          <th class="table__head--text table__data--right">Aantal</th>
          <th class="table__head--text table__data--right">Omzet</th>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in customer_per_seller"
            :key="key"
            :class="`table__row--color`"
          >
            <td class="table__cell--default">
              <router-link
                :to="{
                  name: 'klantpagina',
                  params: { id: item.klant },
                }"
                >{{ item.klant }}</router-link
              >
            </td>
            <td class="table__cell--default">{{ item.naam }}</td>
            <td class="table__cell--default table__data--right">
              {{ item.aantal }}
            </td>
            <td class="table__cell--default table__data--right">
              € {{ item.verkopen }}
            </td>
          </tr>
        </tbody>
        <tfoot class="table__foot">
          <th class="table__foot--padding">
            {{ customer_per_seller_sum.aantal_klanten }}
          </th>
          <th class="table__foot--padding"></th>
          <th class="table__foot--padding table__data--right">
            {{ customer_per_seller_sum.aantal }}
          </th>
          <th class="table__foot--padding table__data--right">
            € {{ customer_per_seller_sum.verkopen }}
          </th>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";

export default {
  props: ["bu", "name", "year"],
  components: { Loading },
  data: () => ({
    loading: true,
    customer_per_seller: null,
    customer_per_seller_sum: null,
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      request(
        `customers-per-seller/${this.name}/${this.year}/${this.bu}`,
        "GET"
      ).then(({ customer_per_seller, customer_per_seller_sum }) => {
        this.customer_per_seller = customer_per_seller;
        this.customer_per_seller_sum = customer_per_seller_sum;
        this.loading = false;
      });
    },
  },
};
</script>